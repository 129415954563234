import { EVENT_PROPERTY_KEY } from '~/lib/constants/event'

export default function () {
  const event = ref(null)
  const eventTextColor = ref(null)
  const eventBackgroundColor = ref(null)
  const { $eventService } = useNuxtApp()

  /**
   * @param {Proxy} event
   */
  function setEventProperties(event) {
    const textColor = $eventService.getEventPropertyByKey(event.properties, EVENT_PROPERTY_KEY.TEXT_COLOR)?.value
    const backgroundColor = $eventService.getEventPropertyByKey(
      event.properties,
      EVENT_PROPERTY_KEY.BACKGROUND_COLOR,
    )?.value

    if (textColor) {
      eventTextColor.value = textColor
    }

    if (backgroundColor) {
      eventBackgroundColor.value = backgroundColor
    }
  }

  return { event, eventTextColor, eventBackgroundColor, setEventProperties }
}
